import Vue from 'vue'
import Vuex from 'vuex'
import { Module, VuexModule, Mutation, MutationAction, Action } from 'vuex-module-decorators'
import { Device, Agent, FileInfo, Config, Job, Notification, UISettingDesc, DeviceUISetting, AgentUISetting, ROLE_USER, ROLE_PROXY } from '../../dataTypes'
import * as appConfig from '../../appConfig'
import { StringUtils } from '@/utils/StringUtils'

Vue.use(Vuex);

const option = {
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
};

@Module({ namespaced: true })
export default class UserStores extends VuexModule {
  static sCert: string;

  _customerName = "";
  _userRole = ROLE_USER;
  _proxyName = "";
  _notifications: Notification[] = [];
  _userInterfaceSetting: { [key: string]: DeviceUISetting | AgentUISetting } = {};

  get customerName(): string {
    return this._customerName;
  }

  get proxyName(): string {
    return this._proxyName;
  }

  get isProxy(): boolean {
    return this._userRole == ROLE_PROXY;
  }

  get notifications(): Notification[] {
    return this._notifications;
  }

  get notification(): (arg: string) => Notification | undefined {
    return (notificationId: string): Notification | undefined => {
      return this._notifications.find((el: Notification) => el.notificationId == notificationId);
    }
  }

  get uiSetting(): (arg: string) => DeviceUISetting | AgentUISetting | undefined {
    return (key: string): DeviceUISetting | AgentUISetting | undefined => {
      return this._userInterfaceSetting[key];
    }
  }

  static checkCert(cert: string): void {
    if (cert != UserStores.sCert) {
      throw new Error("-1");
    }
  }

  @Mutation
  storeUISetting(setting: UISettingDesc): void {
    this._userInterfaceSetting[setting.key] = Object.assign({}, setting.setting as DeviceUISetting | AgentUISetting);
  }

  @Mutation
  updateNotification(notification: Notification | null): void {
    if (notification && notification.notificationId) {
      const index = this._notifications.findIndex((el) => {
        return notification.notificationId == el.notificationId;
      });
      if (index != -1) {
        const newNotification: Notification[] = Object.assign([], this._notifications);
        newNotification[index] = notification;
        this._notifications = newNotification;
      }
    }
  }


  @MutationAction({mutate: ['_customerName', '_userRole', '_proxyName']})
  async fetchCustomerName(): Promise<{ _customerName: string, _userRole: number, _proxyName: string }> {
    const dataStr = JSON.stringify({
      req_type: "GetAccount",
      userRole: (this.state as { _userRole: number })._userRole
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      UserStores.checkCert(res.data.cert);
      return { _customerName: res.data.account, _userRole: res.data.isProxy ? ROLE_PROXY: ROLE_USER, _proxyName: res.data.proxyName }
    }
    throw new Error(res.data.status);
  }

  @MutationAction({mutate: ['_customerName', '_userRole', '_proxyName']})
  async logout(): Promise<{ _customerName: string, _userRole: number, _proxyName: string }> {
    const dataStr = JSON.stringify({
      req_type: "Logout",
      userRole: (this.state as { _userRole: number })._userRole
    });

    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      UserStores.checkCert(res.data.cert);
      return { _customerName: "", _userRole: ROLE_USER, _proxyName: "" };
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async fetchDevices(param: {
                              deviceId: string,
                              locationFilterValue: string,
                              modelNameFilterValue: string,
                              firmwareVersionFilterValue: string,
                              serialNumberFilterValue: string,
                              deviceIdFilterValue: string,
                              deviceStatusFilterValue: number,
                              errorType: number,
                              deviceModel: string,
                              options: {
                                sortBy: string[],
                                sortDesc: boolean[],
                                page: number,
                                itemsPerPage: number
                              }
                          }): Promise<{ devices: Device[], count: number }> {
    const dataStr = JSON.stringify({
      req_type: "GetDevicesEx",
      req_data: {
        itemsPerPage: param.options ? param.options.itemsPerPage: null,
        page: param.options ? param.options.page: null,
        sortBy: param.options ? param.options.sortBy[0]: null,
        sortDesc: param.options ? param.options.sortDesc[0]: null,
        deviceId: param.deviceId,
        locationFilterValue: param.locationFilterValue,
        modelNameFilterValue: param.modelNameFilterValue,
        firmwareVersionFilterValue: param.firmwareVersionFilterValue,
        serialNumberFilterValue: param.serialNumberFilterValue,
        deviceIdFilterValue: param.deviceIdFilterValue,
        deviceStatusFilterValue: param.deviceStatusFilterValue,
        errorType: param.errorType,
        deviceModel: param.deviceModel,
      },
      userRole: this._userRole,
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      UserStores.checkCert(res.data.cert);
      return { devices: res.data.devices, count: res.data.count };
    }
    throw new Error(res.data.status);
  }

  @Action({ commit: 'updateDevice' })
  async editDevice(param: unknown): Promise<Device> {
    const dataStr = JSON.stringify({
      req_type: "EditDevice",
      req_data: param,
      userRole: this._userRole
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      UserStores.checkCert(res.data.cert);
      return res.data.device;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async fetchAgents(param: {
                      agentId: string,
                      locationFilterValue: string,
                      agentIdFilterValue: string,
                      options: {
                        sortBy: string[],
                        sortDesc: boolean[],
                        page: number,
                        itemsPerPage: number
                      }}): Promise<{ agents: [], count: number }> {
    const dataStr = JSON.stringify({
      req_type: "GetAgentsEx",
      req_data: {
        userRole: this._userRole,
        agentId: param.agentId,
        itemsPerPage: param.options ? param.options.itemsPerPage: null,
        page: param.options ? param.options.page: null,
        sortBy: param.options ? param.options.sortBy[0]: null,
        sortDesc: param.options ? param.options.sortDesc[0]: null,
        locationFilterValue: param.locationFilterValue,
        agentIdFilterValue: param.agentIdFilterValue,
      },
      userRole: this._userRole
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      UserStores.checkCert(res.data.cert);
      return { agents: res.data.agents, count: res.data.count }
    }
    throw new Error(res.data.status);
  }

  @Action({ commit: 'updateAgent' })
  async editAgent(param: unknown): Promise<Agent> {
    const dataStr = JSON.stringify({
      req_type: "EditAgent",
      req_data: param,
      userRole: this._userRole
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      UserStores.checkCert(res.data.cert);
      return res.data.agent;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async fetchSupportedFirmwares(param: unknown): Promise<{supportedFirmwares: [] }> {
    const dataStr = JSON.stringify({
      req_type: "GetSupportedFirmwares",
      req_data: param,
      userRole: this._userRole
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      UserStores.checkCert(res.data.cert);

      Object.keys(res.data.supportedFirmwares).forEach((key: string) => {
        res.data.supportedFirmwares[key].sort((a: { releaseDate: number }, b: { releaseDate: number }) => {
          const dateA = new Date(a.releaseDate);
          const dateB = new Date(b.releaseDate);
          return dateA.getTime() - dateB.getTime();
        });
      });
      return { supportedFirmwares: res.data.supportedFirmwares }
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async fetchFiles(): Promise<{ files: [] }> {
    const dataStr = JSON.stringify({
      req_type: "GetFiles",
      userRole: this._userRole
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      UserStores.checkCert(res.data.cert);
      return { files: res.data.files }
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async fetchConfig(param: unknown): Promise<Config> {
    const dataStr = JSON.stringify({
      req_type: "GetConfig",
      req_data: param,
      userRole: this._userRole
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      UserStores.checkCert(res.data.cert);
      if (res.data.config && res.data.config.editConfigData) {
        // 後で関数化する
        const decodedUtf8str = atob(res.data.config.editConfigData);
        const a = Array.prototype.map.call(decodedUtf8str, (c) => {
          return c.charCodeAt();
        }) as number[];
        const decodedArray = new Uint8Array(a);
        res.data.config.editConfigData = new TextDecoder().decode(decodedArray);
      }
      return res.data.config;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async setConfig(arg: { device: Device, config: string }): Promise<Config> {
    const a = new TextEncoder().encode(arg.config);
    const configStr = String.fromCharCode.apply(null, Array.from(a));
    const encodedConfigStr = btoa(configStr);    

    const param: { deviceId: string, configData: string } = {
      deviceId: arg.device.deviceId,
      configData: encodedConfigStr
    };

    const dataStr = JSON.stringify({
      req_type: "SetConfig",
      req_data: param,
      userRole: this._userRole
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      UserStores.checkCert(res.data.cert);
      if (res.data.config) {
        // 後で関数化する
        const decodedUtf8str = atob(res.data.config.editConfigData);
        const a = Array.prototype.map.call(decodedUtf8str, (c) => {
          return c.charCodeAt();
        }) as number[];
        const decodedArray = new Uint8Array(a);
        res.data.config.editConfigData = new TextDecoder().decode(decodedArray);
      }
      return res.data.config;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async registerFile(param: FileInfo): Promise<FileInfo> {
    const body = new FormData();
    body.append('file', param.file as File);
    
    Object.assign(param as { userRole: number }, { userRole: this._userRole })
    
    const dataStr = JSON.stringify({
      req_type: "AddFile",
      req_data: param,
      userRole: this._userRole
    });
    body.append('data', dataStr);

    const res = await Vue.axios.post(appConfig.cgiUrl, body, { withCredentials: true });
    if (res.status == 200 && res.data.status == 0) {
      UserStores.checkCert(res.data.cert);
      return res.data.file;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async unregisterFile(param: unknown): Promise<string> {
    const dataStr = JSON.stringify({
      req_type: "DeleteFile",
      req_data: param,
      userRole: this._userRole
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      UserStores.checkCert(res.data.cert);
      return res.data.file.fileId;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async fetchAgentDevices(param: {
                            agentId: string,
                            options: {
                              sortBy: string[],
                              sortDesc: boolean[],
                              page: number,
                              itemsPerPage: number
                            }
                        }): Promise<{ agentId: string, devices: [], count: number }> {
    const dataStr = JSON.stringify({
      req_type: "GetAgentDevicesEx",
      req_data: {
        agentId: param.agentId,
        itemsPerPage: param.options.itemsPerPage,
        page: param.options.page,
        sortBy: param.options.sortBy[0],
        sortDesc: param.options.sortDesc[0],
      },
      userRole: this._userRole
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      UserStores.checkCert(res.data.cert);
      return { agentId: res.data.agentId, devices: res.data.devices, count: res.data.count };
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async prepareFindAgentDevices(param: unknown): Promise<{ agentId: string, devices: [] }> {
    const dataStr = JSON.stringify({
      req_type: "PrepareFindAgentDevices",
      req_data: param
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      UserStores.checkCert(res.data.cert);
      return { agentId: res.data.agentId, devices: res.data.devices};
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async registerAgentDevices(param: unknown): Promise<{ agentId: string, devices: [] }> {
    const dataStr = JSON.stringify({
      req_type: "RegisterAgentDevices",
      req_data: param,
      userRole: this._userRole
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      UserStores.checkCert(res.data.cert);
      return { agentId: res.data.agentId, devices: res.data.devices};
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async fetchJobs(param: {
                              second: number,
                              command: string,
                              jobId: string,
                              statusFilterValue: number,
                              locationFilterValue: string,
                              deviceIdFilterValue: string,
                              ignorePushCommand: boolean,
                              options: {
                                sortBy: string[],
                                sortDesc: boolean[],
                                page: number,
                                itemsPerPage: number
                              }
                          }): Promise<{ jobs: Job[], count: number }> {
    const dataStr = JSON.stringify({
      req_type: "GetJobsEx",
      req_data: {
        isPastJob: false,
        jobId: param.jobId,
        command: param.command,
        second: param.second ? param.second: -1,
        itemsPerPage: param.options.itemsPerPage,
        page: param.options.page,
        sortBy: param.options.sortBy[0],
        sortDesc: param.options.sortDesc[0],
        statusFilterValue: param.statusFilterValue,
        locationFilterValue: param.locationFilterValue,
        deviceIdFilterValue: param.deviceIdFilterValue,
        ignorePushCommand: param.ignorePushCommand,
      },
      userRole: this._userRole
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      UserStores.checkCert(res.data.cert);
      return { jobs: res.data.jobs, count: res.data.count };
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async fetchPastJobs(param: {
                                second: number,
                                confirmedFilterValue: number,
                                locationFilterValue: string,
                                deviceIdFilterValue: string,
                                ignorePushCommand: boolean,
                                options: {
                                  sortBy: string[],
                                  sortDesc: boolean[],
                                  page: number,
                                  itemsPerPage: number
                                }
                            }): Promise<{ jobs: Job[], count: number }> {
    const dataStr = JSON.stringify({
      req_type: "GetJobsEx",
      req_data: {
        isPastJob: true,
        second: param.second ? param.second: -1,
        itemsPerPage: param.options.itemsPerPage,
        page: param.options.page,
        sortBy: param.options.sortBy[0],
        sortDesc: param.options.sortDesc[0],
        confirmedFilterValue: param.confirmedFilterValue,
        locationFilterValue: param.locationFilterValue,
        deviceIdFilterValue: param.deviceIdFilterValue,
        ignorePushCommand: param.ignorePushCommand,
      },
      userRole: this._userRole
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      UserStores.checkCert(res.data.cert);
      return { jobs: res.data.jobs, count: res.data.count };
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async createJob(param: unknown): Promise<Job> {
    const dataStr = JSON.stringify({
      req_type: "PutJob",
      req_data: param,
      userRole: this._userRole
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      UserStores.checkCert(res.data.cert);
      return res.data.job;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async deleteJob(param: unknown): Promise<string> {
    const dataStr = JSON.stringify({
      req_type: "DeleteJob",
      req_data: param,
      userRole: this._userRole
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      UserStores.checkCert(res.data.cert);
      return res.data.jobId;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async confirmJobs(param: unknown): Promise<Job[]> {
    const dataStr = JSON.stringify({
      req_type: "ConfirmJob",
      req_data: param,
      userRole: this._userRole
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      UserStores.checkCert(res.data.cert);
      return [res.data.job];
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async getJobCount(): Promise<{ jobCount: number, errorJobCount: number }> {
    const dataStr = JSON.stringify({
      req_type: "GetJobCount",
      userRole: this._userRole,
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      UserStores.checkCert(res.data.cert);
      return { jobCount: res.data.jobCount, errorJobCount: res.data.errorJobCount };
    }
    throw new Error(res.data.status);
  }


  @Action({ rawError: true })
  async changePassword(param: unknown): Promise<number> {
    const dataStr = JSON.stringify({
      req_type: "ChangePassword",
      req_data: param,
      userRole: this._userRole
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      UserStores.checkCert(res.data.cert);
      return res.data.status;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async startApp(): Promise<number> {
    const dataStr = JSON.stringify({
      req_type: "GetCert",
      userRole: this._userRole,
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      UserStores.sCert = res.data.cert;
      return res.data.status;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async deletePrinter(param: unknown): Promise<string> {
    const dataStr = JSON.stringify({
      req_type: "DeleteDevice",
      req_data: param,
      userRole: this._userRole
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      UserStores.checkCert(res.data.cert);
      return (param as { deviceId: string }).deviceId;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async fetchPrivacyPolicy(language: number): Promise<Notification> {
    const dataStr = JSON.stringify({
      req_type: "GetPrivacyPolicy",
      req_data: {
        language: language
      },
      userRole: this._userRole
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      UserStores.sCert = res.data.cert;
      let norification: Notification = {};
      if (res.data.notification) {
        norification = Object.assign({}, res.data.notification);
        if (norification.body) {
          norification.body = StringUtils.decodeBase64(res.data.notification.body);
        }
      }
      return norification;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async fetchLicenseAgreement(language: number): Promise<Notification> {
    const dataStr = JSON.stringify({
      req_type: "GetLicenseAgreement",
      req_data: {
        language: language
      },
      userRole: this._userRole
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      UserStores.sCert = res.data.cert;
      let norification: Notification = {};
      if (res.data.notification) {
        norification = Object.assign({}, res.data.notification);
        if (norification.body) {
          norification.body = StringUtils.decodeBase64(res.data.notification.body);
        }
      }
      return norification;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async fetchNotification(param: unknown): Promise<Notification> {
    const dataStr = JSON.stringify({
      req_type: "GetNotification",
      req_data: param,
      userRole: this._userRole
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      UserStores.sCert = res.data.cert;
      let norification: Notification = {};
      if (res.data.notification) {
        norification = Object.assign({}, res.data.notification);
        if (norification.body) {
          norification.body = StringUtils.decodeBase64(res.data.notification.body);
        }
      }
      return norification;
    }
    throw new Error(res.data.status);
  }

  @MutationAction({mutate: ['_notifications']})
  async fetchNotifications(): Promise<{ _notifications: [] }> {
    const dataStr = JSON.stringify({
      req_type: "GetNotifications",
      userRole: (this.state as { _userRole: number })._userRole
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      UserStores.checkCert(res.data.cert);
      res.data.notifications.forEach((elm: Notification) => {
        elm.startDate = StringUtils.toDate(elm.startDate as string) as Date;
        elm.endDate = StringUtils.toDate(elm.endDate as string) as Date;
        elm.registerDate = StringUtils.toDate(elm.registerDate as unknown as string) as Date;
        if (elm.markDate) {
          elm.markDate = StringUtils.toDate(elm.markDate as unknown as string) as Date;
        }
        if (elm.suppressDispDate) {
          elm.suppressDispDate = StringUtils.toDate(elm.suppressDispDate as unknown as string) as Date;
        }
      });
      return { _notifications: res.data.notifications };
    }
    throw new Error(res.data.status);
  }

  @Action({ commit: 'updateNotification', rawError: true })
  public async setNotificationMark(param: unknown): Promise<Notification> {
    const dataStr = JSON.stringify({
      req_type: "SetNotificationState",
      req_data: param,
      userRole: this._userRole
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      UserStores.checkCert(res.data.cert);
      res.data.notification.startDate = StringUtils.toDate(res.data.notification.startDate);
      res.data.notification.endDate = StringUtils.toDate(res.data.notification.endDate);
      res.data.notification.registerDate = StringUtils.toDate(res.data.notification.registerDate);
      if (res.data.markDate) {
        res.data.markDate = StringUtils.toDate(res.data.markDate as unknown as string) as Date;
      }
      if (res.data.suppressDispDate) {
        res.data.suppressDispDate = StringUtils.toDate(res.data.suppressDispDate as unknown as string) as Date;
      }
    return res.data.notification;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async getModelContentInfo(param: unknown): Promise<string> {
    const dataStr = JSON.stringify({
      req_type: "GetModelContentInfo",
      req_data: param,
      userRole: this._userRole
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      UserStores.checkCert(res.data.cert);
      return res.data.modelContentInfo;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async getCmddDefData(param: unknown): Promise<string> {
    const dataStr = JSON.stringify({
      req_type: "GetCmdDefData",
      req_data: param,
      userRole: this._userRole
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      UserStores.checkCert(res.data.cert);
      return res.data.cmdDefDatas;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async fetchNicConfig(param: unknown): Promise<string> {
    const dataStr = JSON.stringify({
      req_type: "GetNicConfig",
      req_data: param,
      userRole: this._userRole
    });

    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      UserStores.checkCert(res.data.cert);
      if (res.data.nicconfig && res.data.nicconfig.configData) {
        // 後で関数化する
        const decodedUtf8str = atob(res.data.nicconfig.configData);
        const a = Array.prototype.map.call(decodedUtf8str, (c) => {
          return c.charCodeAt();
        }) as number[];
        const decodedArray = new Uint8Array(a);
        res.data.nicconfig.configData = new TextDecoder().decode(decodedArray);
      }
      return res.data.nicconfig;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async setNicConfig(param: unknown): Promise<number> {
    const dataStr = JSON.stringify({
      req_type: "SetNicConfig",
      req_data: param,
      userRole: this._userRole
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      UserStores.checkCert(res.data.cert);
      return res.data.status;
    }
    throw new Error(res.data.status);
  }

  @Action
  saveUISetting(setteing: UISettingDesc): void {
    this.context.commit('storeUISetting', setteing)
  }

  @Action({ rawError: true })
  async fetchSummary(): Promise<unknown> {
    const dataStr = JSON.stringify({
      req_type: "GetSummary",
      userRole: this._userRole
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      UserStores.checkCert(res.data.cert);
      return res.data.summary as unknown;
    }
    throw new Error(res.data.status);
  }
}
