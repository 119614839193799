import Vue from 'vue'
import Vuex from 'vuex'
import UserStores from './user/user-store'
import AdminStores from './admin/admin-store'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user: UserStores,
    admin: AdminStores
  }
})
