















































































































import Vue from 'vue'

export default Vue.extend({
  name: 'LicenseAgreementDialog',

  data: () => ({
    isAgreeButtonEnabled: false
  }),
  methods: {
    onAgree() {
      this.$emit('close-request', true);
    }
  },
  computed: {
  },
  watch: {
    isVisible: {
      handler: function(newVal, oldVal) {
        if (!oldVal && newVal) {
          setTimeout(() => {
            const targetElement = this.$refs.messageLabel;
            if (targetElement) {
              (targetElement as Element).setAttribute('data-mark', this.$t("license_agreement_dlg.label_message_mark") as string);
            }
            if (this.$refs.textArea) {
              const textArea: HTMLElement = this.$refs.textArea as HTMLElement;
              textArea.scrollTop = 0;
              this.isAgreeButtonEnabled = textArea.scrollHeight == textArea.clientHeight;
              if (this.approve) {
                textArea.onscroll = () => {
                  if (textArea.scrollHeight - (textArea.clientHeight + textArea.scrollTop) < 2) {
                    this.isAgreeButtonEnabled = true;
                  }               
                }
              }
            }
          }, 0);            
        }
      }
    }
  },
  props: {
    isVisible: Boolean,
    message: String,
    approve: Boolean,
  }
})
