import Vue from 'vue'
import Vuex from 'vuex'
import { Module, VuexModule, Mutation, Action, MutationAction } from 'vuex-module-decorators'
import { Customer, Agent, Firmware, Notification, Model, Admin, UISettingDesc, CustomerUISetting, ROLE_ADMIN } from '../../dataTypes'
import * as appConfig from '../../appConfig'
import { StringUtils } from '@/utils/StringUtils'

Vue.use(Vuex);

const option = {
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
};

@Module({ namespaced: true })
export default class AdminStores extends VuexModule {
  static sCert: string;

  _adminName = "";
  _isSuper = false;
  _userInterfaceSetting: { [key: string]: CustomerUISetting } = {};

  get adminName(): string {
    return this._adminName;
  }

  get isSuper(): boolean {
    return this._isSuper;
  }

  get uiSetting(): (arg: string) => CustomerUISetting | undefined {
    return (key: string): CustomerUISetting | undefined => {
      return this._userInterfaceSetting[key];
    }
  }

  static checkCert(cert: string): void {
    if (cert != AdminStores.sCert) {
      throw new Error("-1");
    }
  }

  @Mutation
  storeUISetting(setting: UISettingDesc): void {
    this._userInterfaceSetting[setting.key] = Object.assign({}, setting.setting as CustomerUISetting);
  }

  @MutationAction({mutate: ['_adminName', '_isSuper']})
  async fetchAdminName(): Promise<{ _adminName: string, _isSuper: boolean }> {
    const dataStr = JSON.stringify({
      req_type: "GetAccount",
      userRole: ROLE_ADMIN
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      return { _adminName: res.data.account, _isSuper: res.data.isSuper }
    }
    throw new Error(res.data.status);
  }

  @MutationAction({mutate: ['_adminName']})
  async logout(): Promise<{ _adminName: string }> {
    const dataStr = JSON.stringify({
      req_type: "Logout",
      userRole: ROLE_ADMIN
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      return { _adminName: "" };
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async fetchAdministrators(param: {
                          adminId: string,
                          adminIdFilterValue: string,
                          adminNameFilterValue: string,
                          adminMailFilterValue: string,
                          options: {
                            sortBy: string[],
                            sortDesc: boolean[],
                            page: number,
                            itemsPerPage: number
                          }
                      }): Promise<{ administrators: string, count: number  }> {
    const dataStr = JSON.stringify({
      req_type: "GetAdministrators",
      userRole: ROLE_ADMIN,
      req_data: {
        adminId: param.adminId,
        adminIdFilterValue: param.adminIdFilterValue,
        adminNameFilterValue: param.adminNameFilterValue,
        adminMailFilterValue: param.adminMailFilterValue,
        itemsPerPage: param.options ? param.options.itemsPerPage: null,
        page: param.options ? param.options.page: null,
        sortBy: param.options ? param.options.sortBy[0]: null,
        sortDesc: param.options ? param.options.sortDesc[0]: null,
    }});
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      return { administrators: res.data.administrators, count: res.data.count };
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  public async addAdmin(param: unknown): Promise<Admin> {
    const dataStr = JSON.stringify({
      req_type: "AddAdministrator",
      req_data: param,
      userRole: ROLE_ADMIN
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      return res.data.administrator;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  public async editAdmin(param: unknown): Promise<Admin> {
    const dataStr = JSON.stringify({
      req_type: "EditAdministrator",
      req_data: param,
      userRole: ROLE_ADMIN
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      return res.data.administrator;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  public async deleteAdmin(param: unknown): Promise<Admin> {
    const dataStr = JSON.stringify({
      req_type: "DeleteAdministrator",
      req_data: param,
      userRole: ROLE_ADMIN
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      return res.data.administrator;
    }
    throw new Error(res.data.status);
  }  

  @Action({ rawError: true })
  async fetchCustomers(param: {
                          customerId: string,
                          customerIdFilterValue: string,
                          customerNameFilterValue: string,
                          customerMailFilterValue: string,
                          adminNameFilterValue: string,
                          options: {
                            sortBy: string[],
                            sortDesc: boolean[],
                            page: number,
                            itemsPerPage: number
                          }
                      }): Promise<{ customers: string, count: number  }> {
    const dataStr = JSON.stringify({
      req_type: "GetCustomersEx",
      userRole: ROLE_ADMIN,
      req_data: {
        customerId: param.customerId,
        customerIdFilterValue: param.customerIdFilterValue,
        customerNameFilterValue: param.customerNameFilterValue,
        customerMailFilterValue: param.customerMailFilterValue,
        adminNameFilterValue: param.adminNameFilterValue,
        itemsPerPage: param.options ? param.options.itemsPerPage: null,
        page: param.options ? param.options.page: null,
        sortBy: param.options ? param.options.sortBy[0]: null,
        sortDesc: param.options ? param.options.sortDesc[0]: null,
    }});
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      return { customers: res.data.customers, count: res.data.count };
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  public async addCustomer(param: unknown): Promise<Customer> {
    const dataStr = JSON.stringify({
      req_type: "AddCustomer",
      req_data: param,
      userRole: ROLE_ADMIN
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      return res.data.customer;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  public async editCustomer(param: unknown): Promise<Customer> {
    const dataStr = JSON.stringify({
      req_type: "EditCustomer",
      req_data: param,
      userRole: ROLE_ADMIN
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      return res.data.customer;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  public async deleteCustomer(param: unknown): Promise<Customer> {
    const dataStr = JSON.stringify({
      req_type: "DeleteCustomer",
      req_data: param,
      userRole: ROLE_ADMIN
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      return res.data.customer;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async fetchAgents(param: {
                      customerId: number,
                      options: {
                        sortBy: string[],
                        sortDesc: boolean[],
                        page: number,
                        itemsPerPage: number
                      }
                  }): Promise<{ agents: string, count: number }> {
    const dataStr = JSON.stringify({
      req_type: "GetAgentsEx",
      userRole: ROLE_ADMIN,
      req_data: {
        customerId: param.customerId,
        itemsPerPage: param.options.itemsPerPage,
        page: param.options.page,
        sortBy: param.options.sortBy[0],
        sortDesc: param.options.sortDesc[0],
    }});
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      return { agents: res.data.agents, count: res.data.count };
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  public async addAgent(param: unknown): Promise<Agent> {
    const dataStr = JSON.stringify({
      req_type: "AddAgent",
      req_data: param,
      userRole: ROLE_ADMIN
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      return res.data.agent;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  public async deleteAgent(param: unknown): Promise<Agent> {
    const dataStr = JSON.stringify({
      req_type: "DeleteAgent",
      req_data: param,
      userRole: ROLE_ADMIN
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      return res.data.agent;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  public async setAgentStatus(param: unknown): Promise<Agent> {
    const dataStr = JSON.stringify({
      req_type: "SetAgentStatus",
      req_data: param,
      userRole: ROLE_ADMIN
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      return res.data.agent;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async fetchFirmwares(param: {
                        firmwareIdFilterValue: string,
                        modelNameFilterValue: string,
                        options: {
                          sortBy: string[],
                          sortDesc: boolean[],
                          page: number,
                          itemsPerPage: number
                        }
                    }): Promise<{ firmwares: string }> {
    const dataStr = JSON.stringify({
      req_type: "GetFirmwaresEx",
      userRole: ROLE_ADMIN,
      req_data: {
        firmwareIdFilterValue: param.firmwareIdFilterValue,
        modelNameFilterValue: param.modelNameFilterValue,
        itemsPerPage: param.options.itemsPerPage,
        page: param.options.page,
        sortBy: param.options.sortBy[0],
        sortDesc: param.options.sortDesc[0],
      }});
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      return { firmwares: res.data.firmwares };
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  public async addFirmware(param: Firmware): Promise<Firmware> {
    const body = new FormData();
    body.append('file', param.file);

    const dataStr = JSON.stringify({
      req_type: "AddFirmware",
      req_data: param,
      userRole: ROLE_ADMIN        
    });
    body.append('data', dataStr);

    const res = await Vue.axios.post(appConfig.cgiUrl, body, { withCredentials: true });
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      return res.data.firmware;
    }
    throw new Error(res.data.status);
  }    

  @Action({ rawError: true })
  public async deleteFirmware(param: unknown): Promise<Firmware> {
    const dataStr = JSON.stringify({
      req_type: "DeleteFirmware",
      req_data: param,
      userRole: ROLE_ADMIN
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      return res.data.firmware;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async generateAPIKey(param: unknown): Promise<string> {
    const dataStr = JSON.stringify({
      req_type: "GenerateAPIKey",
      req_data: param,
      userRole: ROLE_ADMIN
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      return res.data.newAPIKey;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async deleteOldAPIKey(param: unknown): Promise<number> {
    const dataStr = JSON.stringify({
      req_type: "DeleteOldAPIKey",
      req_data: param,
      userRole: ROLE_ADMIN
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      return res.data.status;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async fetchCurrentAPIKey(param: unknown): Promise<number> {
    const dataStr = JSON.stringify({
      req_type: "GetAPIKey",
      req_data: param,
      userRole: ROLE_ADMIN
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      return res.data.currentAPIKey;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async createJob(param: unknown): Promise<number> {
    const dataStr = JSON.stringify({
      req_type: "PutJob",
      req_data: param,
      userRole: ROLE_ADMIN
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      return res.data.status;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async fetchSupportedModels(): Promise<{ supportedModels: [] }> {
    const dataStr = JSON.stringify({
      req_type: "GetSupportedModels",
      userRole: ROLE_ADMIN
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      return { supportedModels: res.data.supportedModels };
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async fetchNotifications(param: {
                              notificationCategoryFilterValue: string,
                              notificationTitleFilterValue: string,
                              notificationLanguageFilterValue: string,
                              options: {
                                sortBy: string[],
                                sortDesc: boolean[],
                                page: number,
                                itemsPerPage: number
                              }
                          }): Promise<{ notifications: string, count: number }> {
    const dataStr = JSON.stringify({
      req_type: "GetNotificationsEx",
      userRole: ROLE_ADMIN,
      req_data: {
        notificationCategoryFilterValue: param.notificationCategoryFilterValue,
        notificationTitleFilterValue: param.notificationTitleFilterValue,
        notificationLanguageFilterValue: param.notificationLanguageFilterValue,
        itemsPerPage: param.options.itemsPerPage,
        page: param.options.page,
        sortBy: param.options.sortBy[0],
        sortDesc: param.options.sortDesc[0]
      }
  });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      res.data.notifications.forEach((elm: Notification) => {
        // elm.body = StringUtils.decodeBase64(elm.body as string);
        elm.startDate = StringUtils.toDate(elm.startDate as string) as Date;
        elm.endDate = StringUtils.toDate(elm.endDate as string) as Date;
        elm.registerDate = StringUtils.toDate(elm.registerDate as unknown as string) as Date;
      });
      return { notifications: res.data.notifications, count: res.data.count };
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async fetchNotification(param: unknown): Promise<Notification> {
    const dataStr = JSON.stringify({
      req_type: "GetNotification",
      req_data: param,
      userRole: ROLE_ADMIN
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.sCert = res.data.cert;
      res.data.notification.body = StringUtils.decodeBase64(res.data.notification.body);
      res.data.notification.startDate = StringUtils.toDate(res.data.notification.startDate);
      res.data.notification.endDate = StringUtils.toDate(res.data.notification.endDate);
      res.data.notification.registerDate = StringUtils.toDate(res.data.notification.registerDate);
      return res.data.notification;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  public async addNotification(notification: Notification): Promise<Notification> {

    const param = Object.assign({}, notification)
    if (param.body) {
      param.body = StringUtils.encodeBase64(param.body);
      if (param.startDate) {
        param.startDate = (param.startDate as Date).toISOString();
      }
      if (param.endDate) {
        param.endDate = (param.endDate as Date).toISOString();
      }
    }

    const dataStr = JSON.stringify({
      req_type: "AddNotification",
      req_data: param,
      userRole: ROLE_ADMIN
    });

    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      res.data.notification.body = StringUtils.decodeBase64(res.data.notification.body);
      res.data.notification.startDate = StringUtils.toDate(res.data.notification.startDate);
      res.data.notification.endDate = StringUtils.toDate(res.data.notification.endDate);
      res.data.notification.registerDate = StringUtils.toDate(res.data.notification.registerDate);
      return res.data.notification;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  public async editNotification(notification: Notification): Promise<Notification> {
    const param = Object.assign({}, notification)
    if (param.body) {
      param.body = StringUtils.encodeBase64(param.body);
      if (param.startDate) {
        param.startDate = (param.startDate as Date).toISOString();
      }
      if (param.endDate) {
        param.endDate = (param.endDate as Date).toISOString();
      }
    }

    const dataStr = JSON.stringify({
      req_type: "EditNotification",
      req_data: param,
      userRole: ROLE_ADMIN
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      res.data.notification.body = StringUtils.decodeBase64(res.data.notification.body);
      res.data.notification.startDate = StringUtils.toDate(res.data.notification.startDate);
      res.data.notification.endDate = StringUtils.toDate(res.data.notification.endDate);
      res.data.notification.registerDate = StringUtils.toDate(res.data.notification.registerDate);
      return res.data.notification;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  public async deleteNotification(param: unknown): Promise<Notification> {
    const dataStr = JSON.stringify({
      req_type: "DeleteNotification",
      req_data: param,
      userRole: ROLE_ADMIN
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      return res.data.notification;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async fetchModels(param: {
                      modelName: string,
                      modelCategoryFilterValue: string,
                      modelNameFilterValue: string,
                      options: {
                        sortBy: string[],
                        sortDesc: boolean[],
                        page: number,
                        itemsPerPage: number
                      }
                  }): Promise<{ models: string, count: number }> {
    const dataStr = JSON.stringify({
      req_type: "GetModelsEx",
      userRole: ROLE_ADMIN,
      req_data: {
        modelCategoryFilterValue: param.modelCategoryFilterValue,
        modelNameFilterValue: param.modelNameFilterValue,
        itemsPerPage: param.options ? param.options.itemsPerPage: null,
        page: param.options ? param.options.page: null,
        sortBy: param.options ? param.options.sortBy[0]: null,
        sortDesc: param.options ? param.options.sortDesc[0]: null,
        modelName: param.modelName,
    }});
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      return { models: res.data.models, count: res.data.count };
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  public async addModel(param: unknown): Promise<Model> {
    const dataStr = JSON.stringify({
      req_type: "AddModel",
      req_data: param,
      userRole: ROLE_ADMIN
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      return res.data.model;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  public async editModel(param: unknown): Promise<Model> {
    const dataStr = JSON.stringify({
      req_type: "EditModel",
      req_data: param,
      userRole: ROLE_ADMIN
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      return res.data.model;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  public async deleteModel(param: unknown): Promise<Model> {
    const dataStr = JSON.stringify({
      req_type: "DeleteModel",
      req_data: param,
      userRole: ROLE_ADMIN
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      return res.data.model;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async getModelContentInfo(param: unknown): Promise<string> {
    const dataStr = JSON.stringify({
      req_type: "GetModelContentInfo",
      req_data: param,
      userRole: ROLE_ADMIN
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      return res.data.modelContentInfo;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async setModelContentInfo(param: unknown): Promise<string> {
    const dataStr = JSON.stringify({
      req_type: "SetModelContentInfo",
      req_data: param,
      userRole: ROLE_ADMIN
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      return res.data.modelContentInfo;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async getCmddDefData(param: unknown): Promise<string> {
    const dataStr = JSON.stringify({
      req_type: "GetCmdDefData",
      req_data: param,
      userRole: ROLE_ADMIN
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      return res.data.cmdDefDatas;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async addCmddDefData(param: unknown): Promise<string> {
    const dataStr = JSON.stringify({
      req_type: "AddCmdDefData",
      req_data: param,
      userRole: ROLE_ADMIN
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      return res.data.status;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async deleteCmddDefData(param: unknown): Promise<string> {
    const dataStr = JSON.stringify({
      req_type: "DeleteCmdDefData",
      req_data: param,
      userRole: ROLE_ADMIN
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      return res.data.status;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async requestProxyLoginPassword(param: unknown): Promise<number> {
    const dataStr = JSON.stringify({
      req_type: "RequestProxyLoginPassword",
      req_data: param,
      userRole: ROLE_ADMIN
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      return res.data.status;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async proxyLogin(param: unknown): Promise<number> {
    const dataStr = JSON.stringify({
      req_type: "ProxyLogin",
      req_data: param,
      userRole: ROLE_ADMIN
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.checkCert(res.data.cert);
      return res.data.status;
    }
    throw new Error(res.data.status);
  }

  @Action({ rawError: true })
  async startApp(): Promise<number> {
    const dataStr = JSON.stringify({
      req_type: "GetCert",
      userRole: ROLE_ADMIN
    });
    const res = await Vue.axios.post(appConfig.cgiUrl, dataStr, option);
    if (res.status == 200 && res.data.status == 0) {
      AdminStores.sCert = res.data.cert;
      return res.data.status;
    }
    throw new Error(res.data.status);
  }

  @Action
  saveUISetting(setteing: UISettingDesc): void {
    this.context.commit('storeUISetting', setteing)
  }  
}
