




































import Vue from 'vue'

export default Vue.extend({
  name: 'SessionError',
  data: () => ({
  }),
  methods: {
    onLogin() {
      this.$router.replace({path: '/login'});
      window.location.reload();
    }
  }
})
