import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

interface Navigator {
  userLanguage?: string;
  browserLanguage?: string;
  languages: readonly string[];
  language: string;
}

const navigator: Navigator = window.navigator;

// デフォルト言語の取得
const locale = function(): string {
  // ブラウザの言語情報を取得
  const language =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    navigator["userLanguage"] ||
    navigator["browserLanguage"];

  switch (language?.substr(0, 2)) {
    case "ja":
      return "ja";
    case "en":
      return "en";
    default:
      return "en";
  }
};

// VueI18nインスタンスの生成（言語とメッセージオブジェクトを渡す）
const i18n = new VueI18n({
  locale: locale(),
  dateTimeFormats: {
    en: {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
    },
    ja: {
      short: {
        year: "numeric",
        month: "short",
        day: "numeric",
      },
    },
  },
  silentFallbackWarn: true,
  messages: {
    ja: require("./admin_ja.json"),
    en: require("./admin_en.json"),
  },
});

export default i18n;
