export class StringUtils {
    static format(str: string, ...strArray: string[]): string {
        return str.replace(/{(\d+)}/g, (match, number) => {
            return typeof strArray[number] != 'undefined'? strArray[number]: match;
        });
    }

    static shrinkStr(str?: string): string {
        return str ? str.slice(0, 4) + '...' + str.slice(-4): '';
    }

    static toLocalString(str: string): string {
        if (str) {
          const dateParts = str.split(' ');
          if (dateParts && dateParts.length) {
            const date = new Date(dateParts.length == 1 ? dateParts[0]: dateParts[0] + 'T' + dateParts[1]);
            if (date) {
              return [
                date.getFullYear(),
                date.getMonth() + 1,
                date.getDate()
              ].join( '/' ) + ' ' + date.toLocaleTimeString();
            }
          }
        }
        return '';
      }

    static toLocalDateString(date: Date, sep: string): string {
      return [
              StringUtils.zeroPadding(date.getFullYear(), 4),
              StringUtils.zeroPadding(date.getMonth() + 1, 2),
              StringUtils.zeroPadding(date.getDate(), 2),
            ].join(sep);
    }
    
    static toLocalTimeString(date: Date, sep: string): string {
      return [
              StringUtils.zeroPadding(date.getHours(), 2),
              StringUtils.zeroPadding(date.getMinutes(), 2),
            ].join(sep);
    }

    static toDate(str: string): Date | null {
      let date = null;
      if (str) {
        const dateParts = str.split(' ');
        if (dateParts && dateParts.length) {
          date = new Date(dateParts.length == 1 ? dateParts[0]: dateParts[0] + 'T' + dateParts[1]);
        }
      }
      return date;
    }

    static toDateEx(dateStr: string, timeStr: string): Date {
      let dateParts = dateStr.split("/");
      if (dateParts.length <= 2) {
        dateParts = dateStr.split("-");
      }
      const d = new Date();
      if (dateParts.length >= 3) {
        d.setFullYear(parseInt(dateParts[0]));
        d.setMonth(parseInt(dateParts[1]) - 1);
        d.setDate(parseInt(dateParts[2]));
        d.setHours(0);
        d.setMinutes(0);
        d.setSeconds(0);
        d.setMilliseconds(0);
        if (timeStr) {
          const timeParts = timeStr.split(":");
          if (timeParts.length >= 2) {
            d.setHours(parseInt(timeParts[0]));
            d.setMinutes(parseInt(timeParts[1]));
          }
        }
      }
      return d;
    }

    static zeroPadding(num: string | number, digits: number): string {
      return (Array(digits).join('0') + num).slice(-digits);
    }

    static zeroPaddingR(num: string | number, digits: number): string {
      return (num + Array(digits).join('0')).slice(0, digits);
    }

    static encodeBase64(str: string): string {
      const a = new TextEncoder().encode(str);
      const base64Str = String.fromCharCode.apply(null, Array.from(a));
      return btoa(base64Str);    
    }

    static decodeBase64(base64Str: string): string {
      const decodedUtf8str = atob(base64Str);
      const a = Array.prototype.map.call(decodedUtf8str, (c) => {
        return c.charCodeAt();
      }) as number[];
      const decodedArray = new Uint8Array(a);
      return new TextDecoder().decode(decodedArray);
  }
}