
export interface Device {
  deviceId: string;
  location: string;
  serialNumber: string;
  modelName: string;
  firmwareId: string;
  stateCode: number;
  errorstatecode1: number;
  errorstatecode2: number;
  errorstatecode3: number;
  printCount: number;
  serviceCount: number;
  cutterCount: number;
  category: string;
  logDateTime: string;
  [key: string]: string | number | Firmware[];
  nicSerialNumber: string;
  nicModelName: string;
  nicFirmwareId: string;
}

export interface FileInfo {
  fileId?: string;
  name?: string;
  date?: string;
  file?: File;
  memo?: string;
}

export interface DeviceFWUpdate extends Device {
  deviceId: string;
  firmwares: Firmware[];
  selectFirmware: number;
}

export interface Agent {
  customerId?: string;
  agentId?: string;
  location?: string;
  jobIntervalSec?: number;
  intervalSec?: number;
  stateCode?: number;
}

export interface MSWExtension {
  type: string;
  unit: string;
  value: string;
  defaultValue: string;
}

export interface MSWDesc {
  title?: string;
  label?: string;
  reserved?: string;
  values?: string[];
  defaultIndex?: number;
  selectIndex?: number;
  ext?: MSWExtension;
}

export interface Customer {
  customerId?: string;
  customerName?: string;
  password?: string;
  mail?: string;
  adminId?: string;
  adminName?: string;
}

export interface Firmware {
  firmwareId: string;
  modelNames: string[];
  uri: string;
  releaseDate: Date;
  file: File;
}

export interface Config {
  deviceId: string;
  configData: string;
  editConfigData: string;
}

export interface AgentDevice {
  agentId: string;
  deviceId: string;
  location: string;
  serialNumber: string;
  modelName: string;
  firmwareId: string;
  stateCode: number;
  nicSerialNumber?: string;
  nicModelName?: string;
  nicFirmwareId?: string;
}

export interface Job {
  jobId?: string;
  deviceId: string;
  command: string;
  parameter?: string;
  status?: number;
  atOption?: string;
  agentJobId?: string;
  control?: number;
  retry?: number;
  result?: number;
  ioGatewayId?: string;
  lastUpdate?: string;
  confirmedDateTime?: string;
}

export interface Notification {
  notificationId?: string;
  category?: number;
  title?: string;
  body?: string;
  startDate?: Date | string;
  endDate?: Date | string;
  registerDate?: Date;
  markDate?: Date | string;
  suppressDispDate?: Date | string;
  language?: number;
}

export interface Model {
  name?: string;
  seriesName?: string;
  category?: string;
  printCountLimit?: number;
  cutterCountLimit?: number;
}

export interface ModelContentInfo {
  modelName?: string;
  usersGuideUrl1?: string;
  usersGuideUrl2?: string;
  utilityUrl?: string;
}

export interface ModelInfo {
  model: Model;
  contentInfo: ModelContentInfo;
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  modelDefs;
}

export interface NicConfig {
  deviceId?: string;
  editConfigData?: string;
  certData?: string;
  validityBegin?: string;
  validityEnd?: string;
  wpaEapCaCert?: string;
  wpaEapUserCert?: string;
}

export interface Admin {
  adminId?: string;
  adminName?: string;
  password?: string;
  mail?: string;
}

// ---------------------------------------------------------------------------
//    UserInterface Setting
// ---------------------------------------------------------------------------

export interface DeviceUISetting {
  page: number;
  itemsPerPage: number;
  sortBy: string;
  sortDesc: boolean;
  selected: [];
  filterPanel: number | null;
  locationFilterValue: string;
  modelNameFilterValue: string;
  firmwareVersionFilterValue: string;
  serialNumberFilterValue: string;
  deviceIdFilterValue: string;
  deviceStatusFilterValue: number;
}

export interface AgentUISetting {
  page: number;
  itemsPerPage: number;
  sortBy: string;
  sortDesc: boolean;
  selected: [];
  filterPanel: number | null;
  locationFilterValue: string;
  agentIdFilterValue: string;
}

export interface JobUISetting {
  page: number;
  itemsPerPage: number;
  sortBy: string;
  filterIndex: number;
  sortDesc: boolean;
  filterPanel: number | null;
  locationFilterValue: string;
  deviceIdFilterValue: string;
}


export interface CustomerUISetting {
  page: number;
  itemsPerPage: number;
  sortBy: string;
  sortDesc: boolean;
  filterPanel: number | null;
  customerIdFilterValue: string;
  customerNameFilterValue: string;
  customerMailFilterValue: string;
  adminNameFilterValue: string;
}

export interface AdministratorUISetting {
  page: number;
  itemsPerPage: number;
  sortBy: string;
  sortDesc: boolean;
  filterPanel: number | null;
  adminIdFilterValue: string;
  adminNameFilterValue: string;
  adminMailFilterValue: string;
}

export interface FirmwareUISetting {
  page: number;
  itemsPerPage: number;
  sortBy: string;
  sortDesc: boolean;
  filterPanel: number | null;
  firmwareIdFilterValue: string;
  modelNameFilterValue: string;
}

export interface NotificationUISetting {
  page: number;
  itemsPerPage: number;
  sortBy: string;
  sortDesc: boolean;
  filterPanel: number | null;
  notificationTitleFilterValue: string;
  notificationCategoryFilterValue: number;
  notificationLanguageFilterValue: number;
}

export interface ModelUISetting {
  page: number;
  itemsPerPage: number;
  sortBy: string;
  sortDesc: boolean;
  filterPanel: number | null;
  modelNameFilterValue: string;
  modelCategoryFilterValue: number;
}

export interface UISettingDesc {
  key: string;
  setting: DeviceUISetting | AgentUISetting | CustomerUISetting;
}

// ---------------------------------------------------------------------------

export interface LangDesc {
  text: string;
  value: string;
}

export const ROLE_USER = 0;
export const ROLE_ADMIN = 1;
export const ROLE_PROXY = 2;
