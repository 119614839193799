























































import Vue, { PropType } from 'vue'
import '@/assets/csj-v1.0/style.css'

export default Vue.extend({
  name: 'MessageBox',
  watch: {
    isVisible: {
      handler: function(newVal, oldVal) {
        if (!oldVal && newVal) {
          requestAnimationFrame(() => {
            ((this.$refs.dialogButtons as Vue[])[this.defaultButtonIndex].$el as HTMLElement).focus();
          });
        }
      }
    }
  },
  props: {
    isVisible: Boolean,
    title: {
      type: String,
      default: ''
    },
    message: {
      type: String,
      default: ''
    },
    closeboxAction: {
      type: String,
      default: null
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    defaultButtonIndex: {
      type: Number,
      default: 0
    },
    isAdmin: {
      type: Boolean,
      default: false
    },
    buttons: {
      type: Array as PropType<{ label: string, action: string }[]>,
      default: [
        { label: 'OK', action: 'close' }
      ]
    }
  }
})
