






























































































































import Vue from 'vue'

export type BreadcrumbDesc = {
  text: string;
  exact?: boolean;
  to?: {
    name: string,
  }
}

export default Vue.extend({
  name: 'Breadcrumbs',
  props: {
    items: [],
    isAdmin: {
      type: Boolean,
      default: false
    }
  }
})
