import VueRouter from 'vue-router'
import { Device } from '../dataTypes'

export const errorStateString1 = [
  { message: "Paper End Error",       mask: 0x01 },
  { message: "Paper Load Error",      mask: 0x01 },
  { message: "Paper Jam Error",       mask: 0x01 },
  { message: "Cutter Error",          mask: 0x01 },
  { message: "Ribbon Run Error",      mask: 0x01 },
  { message: "Ribbon End Error",      mask: 0x01 },
  { message: "Head Resistor Error",   mask: 0x01 },
  { message: "Strobe Error",          mask: 0x01 },
  { message: "Serial Over Run Error", mask: 0x01 },
  { message: "Serial Framing Error",  mask: 0x01 },
  { message: "Serial Parity Error",   mask: 0x01 },
  { message: "Application Error",     mask: 0x01 },
  { message: "Malloc Error",          mask: 0x01 },
  { message: "ReWinder Full Error",   mask: 0x01 },
  { message: "Battery Error",         mask: 0x01 },
  { message: "",                      mask: 0x01 },
];

export const errorStateString2 = [
  { message: "Head Open Error",       mask: 0x01 },
  { message: "Cover Open Error",      mask: 0x01 },
  { message: "Print Cover Open Error",mask: 0x01 },
  { message: "Roller Open Error",     mask: 0x01 },
  { message: "Motor Cold Error",      mask: 0x01 },
  { message: "Motor Hot Error",       mask: 0x01 },
  { message: "Board Cold Error",      mask: 0x01 },
  { message: "Board Hot Error",       mask: 0x01 },
  { message: "Head Hot Error",        mask: 0x01 },
  { message: "Head Cold Error",       mask: 0x09 },
  { message: "Power Hot Error",       mask: 0x01 },
  { message: "Power Cold Error",      mask: 0x01 },
  { message: "Send Over Run Error",   mask: 0x01 },
  { message: "Head Check Error",      mask: 0x09 },
  { message: "",                      mask: 0x01 },
  { message: "",                      mask: 0x01 },
];

export const errorStateString3 = [
  { message: "FROM Error",            mask: 0x01 },
  { message: "RAM Error",             mask: 0x01 },
  { message: "EEPROM Error",          mask: 0x01 },
  { message: "Kanji ROM Error",       mask: 0x01 },
  { message: "Option Board Error",    mask: 0x01 },
  { message: "Pcmcia Error",          mask: 0x01 },
  { message: "Fan Stop Error",        mask: 0x01 },
  { message: "DC24V Error",           mask: 0x01 },
  { message: "Lcd Error",             mask: 0x01 },
  { message: "Rewinder Error",        mask: 0x01 },
  { message: "System Error",          mask: 0x01 },
  { message: "Paper Near Error",      mask: 0x01 },
  { message: "Presenter Error",       mask: 0x01 },
  { message: "",                      mask: 0x01 },
  { message: "",                      mask: 0x01 },
  { message: "",                      mask: 0x01 },
];

export class AppUtils {
    static redirectIfSessionError(router: VueRouter, err: Error): void {
        const errCode = parseInt((err as Error).message);
        if (errCode === -1) {
          router.replace({path: "" + '/session-error'});
          window.location.reload();
        }
    }

    static getStatus(state: number, dateTime: string): number {
      let delta = 0;
      if (dateTime) {
        delta = ((new Date()).getTime() - (new Date(dateTime + 'Z')).getTime()) / 1000;
      }
      if (!dateTime || delta > 60 * 60) {  // 不明
        return 3;
      } else if (state == 2) {    // 正常
        return 1;
      }
      return 2;                   // エラー
    }

    static getErrorMessage(device: Device, mask = 0x01): string {
        let errorStr = '';
        if (device.errorstatecode1 !== undefined && device.errorstatecode1 !== null) {
          for (let i = 0; i < 16; i++) {
            if ((device.errorstatecode1 & (1 << i)) != 0) {
              if (errorStr.length) {
                errorStr += "/";
              }
              if ((errorStateString1[i].mask & mask)) {
                errorStr += errorStateString1[i].message;
              }
            }
          }
        }
        if (device.errorstatecode2 !== undefined && device.errorstatecode2 !== null) {
          for (let i = 0; i < 16; i++) {
            if ((device.errorstatecode2 & (1 << i)) != 0) {
              if (errorStr.length) {
                errorStr += "/";
              }
              if ((errorStateString2[i].mask & mask)) {
                errorStr += errorStateString2[i].message;
              }
            }
          }
        }
        if (device.errorstatecode3 !== undefined && device.errorstatecode3 !== null) {
          for (let i = 0; i < 16; i++) {
            if ((device.errorstatecode3 & (1 << i)) != 0) {
              if (errorStr.length) {
                errorStr += "/";
              }
              if ((errorStateString3[i].mask & mask)) {
                errorStr += errorStateString3[i].message;
              }
            }
          }
        }

        return errorStr;
    }
  }